<template>
  <div class="content">
    <el-row class="bgW" v-if="!clienId">
      <el-col :span="22">
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="姓名/手机号">
            <el-input
              v-model="search.customer_name_or_telephone"
              maxlength="30"
              class="w160"
              placeholder="请输入姓名或手机号"
              clearable
            />
          </el-form-item>
          <el-form-item label="来源">
            <el-select v-model="search.source_id" clearable class="w100">
              <el-option
                v-for="item in options.source_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="客服">
            <el-input
              v-model="search.user_name"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="定时回访日期">
            <el-date-picker
              v-model="search.yjreturn_visit_time"
              type="date"
              class="w130"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>

            <el-date-picker
              v-model="search.yjreturn_visit_time2"
              type="date"
              class="w130"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <div v-if="clienId"></div>
        <el-button
          type="primary"
          v-if="!clienId && !userInfo.shop_id"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表','')"
        >导出数据</el-button>
        <div v-if="userInfo.shop_id"></div>
        <!--  -->
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!clienId && !userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="修改类型" prop="type" />
            <ElTableColumn label="卡项名称" prop="name" />
            <ElTableColumn label="门店" prop="shop_id" />
            <ElTableColumn label="原数据" prop="old_date" />
            <ElTableColumn label="新数据" prop="new_date" />
            <ElTableColumn label="提交人" prop="shop_id" />
            <ElTableColumn label="提交时间" prop="create_time" />
            <ElTableColumn label="审核状态" prop="sh_type" />
            <ElTableColumn label="审核人" prop="sh_id" />
            <ElTableColumn label="审核时间" prop="sh_time" />
            <ElTableColumn label="审核" fixed="right">
              <template slot-scope="{ row }">
                <span v-if="row.sh_type == '已审核'" class="theme" >--</span>
                <span v-else class="theme" @click="tapBtn('',row)">审 核</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>

    <!-- 审核弹框 -->
    <el-dialog title="审 核" :visible.sync="isAuditPop" width="40%">
      <el-form
        :inline="true"
        ref="isAudit"
        size="mini"
        :model="isAudit"
        label-width="150px"
      >
      是否通过该条修改！
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isAuditPop = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitFormC()">确 定</el-button>
      </span>
    </el-dialog>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import {
  auditList,
  clientList,
  ssettingList,
  submitAudit
} from "@/api/client";
class Search {
  customer_name_or_telephone = "";
  source_id = "";
  user_name = "";
  yjreturn_visit_time = "";
  yjreturn_visit_time2 = "";
}
class VisitDetail {
  customer_id = "";
  shop_id = "";
  yjreturn_visit_time = "";
  yjreturn_visit_content = "";
}
class Rules {
  customer_id = [{ required: true, message: "请选择客户", trigger: "change" }];
  yjreturn_visit_time = [
    { required: true, message: "请选择预约回访日期", trigger: "change" }
  ];
  yjreturn_visit_content = [
    { required: true, message: "请填写预约回访内容", trigger: "blur" }
  ];
}
class Options {
  client = [];
  source_id = [];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}

class isAudit {
  service_id = "";
  // num = "";
  // end_time = "";
}
export default {
  name: "ClientList",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      page: new Page(),
      search: new Search(),
      options: new Options(),
      visitDetail: new VisitDetail(),
      isAudit: new isAudit(),
      isAuditPop: false, // 审核弹框
      rules: new Rules(),
      list: [],
      idList: "",
      visitClient: false,
      loading: false
    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      // ssettingList({ page: 1, limit: 100000 }).then(res => {
      //   let that = this;
      //   res.list.forEach(function(item) {
      //     if (item.category_id == 4) {
      //       that.options.source_id.push(item);
      //     }
      //   });
      // });
    },

    // 选择客户
    changeCustomer(e) {
      this.options.client.forEach(item => {
        if (e == item.id) {
          this.visitDetail.shop_id = item.shop_id;
        }
      });
    },
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      auditList({
        ...this.search,
      }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      });
    },
    // 添加定时回访
    addVistListPo() {
      this.visitClient = true;
      this.visitDetail.yjreturn_visit_time = "";
      this.visitDetail.yjreturn_visit_content = "";
    },

    // 确定提交
    confirm(type, val) {
      if (type == "add") {

      } else {

      }
    },

    // 跳转按钮
    tapBtn(routerName, row) {
      console.log(row.update_id);

      this.isAuditPop = true;
      this.isAudit = new isAudit();
      this.isAudit.id = row.id;
    },

    // 审核提交
    submitFormC(formName) {
      submitAudit({
        ...this.isAudit,
      }).then(res => {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.isAuditPop = false;
        this.getList();
      });
    },

    // 提示
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "change") {
            this.submitAudit();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 0px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 30px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
  .entryBox {
    height: 600px;
    overflow-y: scroll;
    .entryRow1 {
      display: flex;
      .item-circle {
        width: 18px;
        height: 18px;
        z-index: 2;
        border-radius: 9px;
        background-color: white;
        border: 5px solid #a5ecd7;
      }
      .clientName {
        line-height: 30px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .entryRow2 {
      color: #999;
      margin: 10px 0 0 20px;
      display: flex;
      font-size: 14px;
    }
    .entryRow3 {
      color: #444;
      margin: 10px 0 20px;
      font-size: 15px;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
.frame {
  .el-form-item {
    margin: 0px 20px 26px 0;
  }
}
.footerBtn {
  text-align: right;
}
</style>
